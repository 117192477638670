import React, { useRef, useEffect } from 'react'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, colors, alpha, globals } from '../../style'
import { WrapperSection } from '../../wrappers'
import { ScrollDots } from '../../elements'
import SideLine from '../../elements/SideLine/SideLine'
import ScrollContainer from 'react-indiana-drag-scroll'
const Certificate = ({ data, theme }) => {
  const { description, image, subtitle, title } = data
  // console.log('here', data)
  return (
    <div css={[sCertificate.wrapper, { height: 250 }]}>
      <div css={[sCertificate, sCertificateTheme(theme)]}>
        {image && (
          <Img
            className="certyfikatyIMG"
            style={sImage}
            fluid={image.childImageSharp.fluid}
            alt={image.name}
          />
        )}
        <T
          d={24}
          m={24}
          mb={1.5}
          bold
          condensed
          center
          variant={title.variant}
          extraCss={{ color: colors[theme].main }}>
          {title}
        </T>
        <T
          d={16}
          m={24}
          mt={-1.5}
          mb={1.5}
          o={0.6}
          bold
          condensed
          center
          extraCss={{ color: colors[theme].main }}>
          {subtitle}
        </T>
        <T o={0.6} d={14} m={12} mb={2.5}>
          {description}
        </T>
      </div>
    </div>
  )
}
const Certificates = ({ data, theme }) => {
  const { items, slug_section, title } = data
  const refCertificates = useRef(null)

  useEffect(() => {
    if (refCertificates.current)
      setTimeout(() => refCertificates?.current?.scrollTo({ left: 1 }), 0)
  }, [])
  // console.log('certyfikaty', items)
  // console.log('DATAAA', data)
  return (
    <section id={slug_section} css={{ contetnVisibility: 'auto' }}>
      <WrapperSection
        outside={<SideLine theme={theme}>{title}</SideLine>}></WrapperSection>
      <div css={sCertificates}>
        <ScrollContainer
          nativeMobileScroll
          innerRef={refCertificates}
          css={[
            sCertificatesContainer,
            // { [s.md]: { width: features.length * 460 } },
            // features.length <= 3
            //   ? { [s.md]: { justifyContent: 'center' } }
            //   : { [s.md]: { marginLeft: 100 } },
          ]}>
          {items?.map((certificate, id) => (
            <Certificate key={id} data={certificate} theme={theme} />
          ))}
        </ScrollContainer>
      </div>
      {/* <ScrollDots
        refElement={refCertificates}
        arr={items}
        extraCss={sDots(theme)}
      /> */}
    </section>
  )
}

export default Certificates
const sCertificatesContainer = {
  display: 'flex',
  overflowX: 'auto',
  [s.md]: { padding: '4rem 2rem 4rem' },
  [s.sm_down]: { padding: '4rem 0rem 0rem' },
  // overflowY: 'hidden',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}
const sCertificates = {
  display: 'flex',
  overflowX: 'auto',
  padding: '2rem 0rem',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}

const sCertificate = {
  wrapper: {
    [s.sm_down]: { padding: `0 ${globals.spacing.inside.mobile}px` },
    [s.md]: { padding: `0 ${globals.spacing.inside.desktop / 4}px` },
    position: 'relative',
  },
  cursor: 'pointer',
  transition: 'transform 0.25s ease-out, box-shadow 0.25s ease-out',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  flexShrink: 0,
  width: 366,
  height: '100%',
  borderRadius: 16,
  padding: '2rem',
  [s.xs]: {
    width: `calc(100vw - ${globals.spacing.inside.mobile * 2}px)`,
  },

  textWrapper: {
    position: 'relative',
    fontStyle: 'italic',
    [s.sm_down]: { marginBottom: globals.spacing.inside.mobile },
    [s.md]: { marginBottom: globals.spacing.inside.desktop / 4 },
  },

  image: {
    width: 40,
    height: 40,
    borderRadius: 32,
  },

  avatar: {
    width: 40,
    height: 40,
    opacity: 0.3,
  },

  author: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  author_text: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0.75rem',
    '.comment-author-title': {
      opacity: 0.3,
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    '.comment-author-subtitle': {
      opacity: 0.3,
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  },
}
const sImage = {
  width: 96,
  height: 96,
  position: 'absolute',
  top: 0,
  left: '10%',
  transform: 'translate(-50%,-50%)',
  borderRadius: 64,
  boxShadow: `0px 8px 32px ${colors.black.concat(alpha[16])}`,
  transition: 'transform 0.3s ease-out',
}
const sCertificateTheme = (theme) => ({
  backgroundColor: colors[theme].container.concat(alpha[30]),
  boxShadow: `0px 8px 16px ${colors[theme].mainDark.concat(alpha[4])}`,
  '&:hover': {
    [s.hover]: {
      // transform: 'scale(1.05) translateY(-16px)',
      boxShadow: `0px 8px 32px ${colors[theme].mainDark.concat(alpha[16])}`,
      '.certyfikatyIMG': {
        transform: 'scale(0.9)',
      },
    },
  },
})
const sDots = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 2rem',
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: colors[theme].main,
  },
  inactive: {
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.16,
    padding: `0 6px`,
    '&:hover': {
      [s.hover]: {
        opacity: 0.6,
      },
    },
  },
  active: {
    opacity: 1,
    '&:hover': {
      [s.hover]: {
        opacity: 1,
      },
    },
  },
})
